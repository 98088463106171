import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/BilGen-ikon-yanda.png";

import Nav from "./Nav";
import { useAppContext } from "@/context/Context";

const MobileMenu = () => {
  const { mobile, setMobile } = useAppContext();

  return (
    <>
      <div className={`popup-mobile-menu ${mobile ? "" : "active"}`}>
        <div className="inner-wrapper">
          <div className="inner-top">
            <div className="content">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    width={137}
                    height={45}
                    alt="Education Logo Images"
                  />
                </Link>
              </div>
              <div className="rbt-btn-close">
                <button
                  className="close-button rbt-round-btn"
                  onClick={() => setMobile(!mobile)}
                >
                  <i className="feather-x"></i>
                </button>
              </div>
            </div>
            <p className="description">
              <strong>Bilgen Yazılım Akademi</strong>, tüm yaşlara online
              yazılım eğitimleri sunan bir platformdur. <strong>Web</strong>,{" "}
              <strong>mobil</strong> ve <strong>masaüstü</strong> uygulama
              geliştirme konularında interaktif ve pratik eğitimler sağlar,
              yazılım dünyasında başarı için gereken becerileri kazandırır.
            </p>
            <ul className="navbar-top-left rbt-information-list justify-content-start">
              <li>
                <Link href="mailto:iletisim@bilgentech.com">
                  <i className="feather-mail"></i>iletisim@bilgentech.com
                </Link>
              </li>
              <li>
                <Link href="tel:+905359221253">
                  <i className="feather-phone"></i>(535) 922-1253
                </Link>
              </li>
            </ul>
          </div>

          <Nav setMobile={setMobile} />

          <div className="mobile-menu-bottom">
            <div className="rbt-btn-wrapper mb--20">
              <Link
                className="rbt-btn btn-border-gradient radius-round btn-sm hover-transform-none w-100 justify-content-center text-center"
                href="/contact"
              >
                <span>İletişime Geç</span>
              </Link>
            </div>

            <div className="social-share-wrapper">
              <span className="rbt-short-title d-block">
                Sosyal Medya Hesaplarımız
              </span>
              <ul className="social-icon social-default transparent-with-border justify-content-start mt--20">
                <li>
                  <Link href="https://www.facebook.com/bilgentechacademy">
                    <i className="feather-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.youtube.com/@BilGenYazilimAkademi">
                    <i className="feather-youtube"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.instagram.com/bilgenyazilimakademisi">
                    <i className="feather-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link href="https://www.linkedin.com/company/bilgen-yazilim-akademi">
                    <i className="feather-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
